import gql from 'graphql-tag'

export const INVENTORY_TYPE_LIST = gql`query {
  inventoryTypeList {
    id
    name
    nameAbbr
    externallyReserved
    color
  }
}`

export const INVENTORY_LIST = gql`query(
  $dateFrom: DateString!
  $dateTo: DateString!
  $facilityId: Int
  $roomTypes:[Int!]
){
  inventoryList(
    dateFrom: $dateFrom
    dateTo: $dateTo
    facilityId: $facilityId
    roomTypes: $roomTypes
  ) {
    roomTypeId
    rooms
    inventoryTypeId
    stayDate
    bookings {
      room { id name }
      blockRoom
    }
  }
}`

export const MW_WEEK_CALENDAR_LIST = gql`query($fromDate: DateString, $toDate: DateString, $facilityId: Int) {
  mwWeekCalendarList(
    fromDate: $fromDate
    toDate: $toDate
    facilityId: $facilityId
  ){
    facilityId
    mwDate
    year
    weekNo
    latterHalf
    mwRank
  }
}`
